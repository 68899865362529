import React, {FunctionComponent, useCallback, useState} from 'react';
import {Button, Empty, List, Popconfirm} from "antd";

import styles from './adventures.module.less';
import {useTranslation} from "react-i18next";
import {useAdventures, useDeleteAdventure, useSetOrganizationCode} from "@hooks/useFirestore";
import {Link, useNavigate} from "react-router-dom";
import {AdventureComponent} from "@packages/components/adventure";
import {DeleteOutlined} from "@ant-design/icons";
import {Adventure, Riddle} from "@packages/types/domain";
import clsx from "clsx";
import {Spinner} from "@packages/components/common/spinner";

const Adventures: FunctionComponent<{}> = () => {
    const {t} = useTranslation();
    const adventures = useAdventures()
    const mutation = useDeleteAdventure()
    const [mutationId, setMutationId] = useState<string | null>(null)
    const organizationCodeMutation = useSetOrganizationCode()

    const deleteAdventure = useCallback(async (adventure: Adventure) => {
        setMutationId(adventure.id)
        try {
            await mutation.mutate(adventure.id)
            const riddlesWithCode = (adventure.riddles || []).filter(r => r.code)
            for (const item of riddlesWithCode) {
                await organizationCodeMutation.mutate({code: item.code!, adventure: adventure.id, riddle: item.id, remove: true})
            }
        } catch (e) {
            setMutationId(null)
        }

    }, [mutation])

    const isLoading = useCallback((adventure: Adventure) => {
        return adventure.id === mutationId
    }, [mutationId])

    if (adventures.isLoading) {
        return <Spinner centered />
    }

    if (adventures?.data?.length === 0) {
        return (<Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            imageStyle={{
                height: 60,
            }}
            description={
                <span>{t('adventures.empty_header')}</span>
            }
        >
            <Link to={'create'}>
                <Button type="primary">{t('adventures.create_new')}</Button>
            </Link>
        </Empty>)
    }

    return (
        <div className={styles.root}>
            <div className={styles.header}>
                <h2>{t('adventures.header')}</h2>
                <Link to={'create'}>
                    <Button type="primary">{t('adventures.create_new')}</Button>
                </Link>
            </div>
            <List
                loading={adventures.isLoading}
                className={styles.list}
                dataSource={adventures.data?.sort((a, b) => a.name.localeCompare(b.name))}
                renderItem={item => (
                    <List.Item
                        key={item.id}
                        actions={[
                            <Link to={item.id}>
                                <Button size="small" type="text">
                                    {t('adventures.open')}
                                </Button>
                            </Link>,
                            <Popconfirm
                                title={t('adventures.delete_adventure')}
                                onConfirm={() => deleteAdventure(item)}
                                okText={t('yes')}
                                cancelText={t('no')}
                            >
                                <Button size="small" disabled={isLoading(item)} type="text" icon={<DeleteOutlined />} />
                            </Popconfirm>

                        ]}
                    >
                        <AdventureComponent mode="user" adventure={item} />
                    </List.Item>
                )}
            />
        </div>
    );
};

export { Adventures }