import React, {ReactNode, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import useFirebaseUser from "@hooks/useFirebaseUser";
import {getOrg, useSetOrganization, useSetUser, useUser} from "@hooks/useFirestore";
import {Spinner} from "@packages/components/common/spinner";
import {Alert, Button, Form, Input} from "antd";

import styles from './organization-checker.module.less'
import {useBreakpoint} from "@packages/components/hooks/useBreakpoint";
import {createId, makeId} from "@packages/commons/src/object";
import {ValidateStatus} from "antd/es/form/FormItem";

type OrganizationCheckerProps = {
    children: ReactNode,
}

type OrganizationForm = {
    organization: string,
}

const CODE_LENGTH = 5
const CODE_AMOUNT = 200

const generateQrCodes = (length: number) => {
    return new Array(length).fill(0).map((_, i) => makeId(CODE_LENGTH));
}

const OrganizationChecker = ({children}: OrganizationCheckerProps) => {
    const {t} = useTranslation()
    const firebaseUser = useFirebaseUser()
    const {data, isLoading} = useUser()
    const mutation = useSetUser()
    const organizationMutation = useSetOrganization()
    const {isSm} = useBreakpoint("sm")
    const [nameExists, setNameExists] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [form] = Form.useForm();
    const orgName = Form.useWatch('organization', form);

    useEffect(() => {
        setNameExists(false)
    }, [orgName])

    const onSubmit = async (data: OrganizationForm) => {
        setIsSubmitting(true)
        const id = createId(data.organization)
        const exists = await getOrg(id)

        if (exists) {
            setNameExists(true)
            setIsSubmitting(false)
            return;
        }

        const user = {
            ...data,
            organizationId: id,
            email: firebaseUser?.email!,
        }
        await mutation.mutate(user)
        const organization = {
            id,
            name: user.organization,
            qrCodes: Object.fromEntries(generateQrCodes(CODE_AMOUNT).map(code => ([code, '']))),
            roles: {
                [user.email]: 'owner'
            },
        }
        await organizationMutation.mutate(organization)
        setIsSubmitting(false)
    }

    if (isLoading) {
        return <Spinner centered />
    }

    if (data?.organization) {
        return <>{children}</>
    }

    const nameValidationProps = nameExists ? {
        validateStatus: "error" as ValidateStatus,
        help: t('organization_form.org_exists')
    } : {}

    return (
        <div>
            <h2>{t('organization_form.header')}</h2>
            <Form
                form={form}
                initialValues={{}}
                onFinish={onSubmit}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 10 }}
                layout="horizontal"
            >
                <Form.Item colon={false} label={isSm ? " " : undefined} >
                    <Alert message={t('organization_form.description')} type="info" showIcon />
                </Form.Item>
                <Form.Item {...nameValidationProps} rules={[{required: true}]} label={t('name', {ns: "common"})} name="organization">
                    <Input placeholder={t('organization_form.name_placeholder')} />
                </Form.Item>
                <Form.Item colon={false} label={isSm ? " " : undefined}>
                    <Input.Group className={styles.buttons}>
                        <Button type="primary" htmlType="submit" loading={mutation.isLoading || organizationMutation.isLoading || isSubmitting} >
                            {t('submit', {ns: "common"})}
                        </Button>
                    </Input.Group>
                </Form.Item>
            </Form>
        </div>
    )
}

export { OrganizationChecker }