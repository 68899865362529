import React, {useCallback, useMemo} from 'react'

import {Button, Form, Input, InputNumber, Select, Switch} from "antd";
import {useTranslation} from "react-i18next";
import {Adventure} from "@packages/types/domain";

import styles from './adventure-form.module.less'
import {useBreakpoint} from "@packages/components/hooks/useBreakpoint";
import {getAdventure, useUser} from "@hooks/useFirestore";
import {Spinner} from "@packages/components/common/spinner";
import {createId} from "@packages/commons/src/object";
import { RuleObject } from 'antd/lib/form';
import { StoreValue } from 'antd/lib/form/interface';
import {Editor} from "@components/common/editor";

type AdventureForm = Partial<Adventure>

export type AdventureFormResult = Omit<Adventure, 'id'>

type AdventureFormProps = {
    initialValues? : Partial<Adventure>
    onSubmit: (adv: AdventureFormResult) => void
    onCancel?: () => void
    isLoading: boolean
}

const DEFAULT_VALUES: AdventureForm = {
    difficulty: 'normal',
    age: 7,
    description: '',
    showPrize: false,
}

const AdventureForm = ({initialValues = DEFAULT_VALUES, onSubmit, isLoading, onCancel = () => {}}: AdventureFormProps) => {
    const { t } = useTranslation()
    const {isSm} = useBreakpoint("sm")
    const [form] = Form.useForm<AdventureFormResult>();
    const showPrize = Form.useWatch<boolean>('showPrize', form);

    const user = useUser()
    const validator = useMemo(() => {
        return (_: RuleObject, value: StoreValue) => {
            if (!value) {
                return Promise.resolve()
            }
            return getAdventure(user.data?.organizationId!, createId(value)).then((res) => {
                if (!res || res.id === initialValues?.id) {
                    return Promise.resolve()
                }
                return Promise.reject('already_exists')
            })
        }
    }, [])


    if (user.isLoading) {
        return <Spinner centered />
    }

    return (
        <Form
            form={form}
            initialValues={initialValues}
            onFinish={onSubmit}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 10 }}
            layout="horizontal"
        >
            <Form.Item validateTrigger="onBlur"  rules={[{required: true}, ({ }) => ({
                message: t('riddle_already_exists'),
                validator})]} label={t('adventure_form.name')} name="name">
                <Input />
            </Form.Item>
            <Form.Item required label={t('adventure_form.difficulty')} name="difficulty">
                <Select>
                    <Select.Option value="easy">{t('difficulty.easy')}</Select.Option>
                    <Select.Option value="normal">{t('difficulty.normal')}</Select.Option>
                    <Select.Option value="hard">{t('difficulty.hard')}</Select.Option>
                </Select>
            </Form.Item>
            <Form.Item required label={t('adventure_form.age')} name="age">
                <InputNumber />
            </Form.Item>
            <Form.Item label={t('adventure_form.description')} name="description">
                <Input.TextArea />
            </Form.Item>
            <Form.Item label={t('adventure_form.prize')}>
                <Form.Item
                    key="showPrize"
                    name="showPrize"
                    noStyle
                >
                    <Switch defaultChecked={initialValues?.showPrize!} className={styles.switch} />
                </Form.Item>
                {
                    showPrize &&
                    <>
                        <Form.Item
                            key="prizeInfo"
                            name="prizeInfo"
                            noStyle
                        >
                            <Editor />
                        </Form.Item>
                    </>
                }
            </Form.Item>
            <Form.Item wrapperCol={{ offset: isSm ? 8 : 0}}>
                <Input.Group className={styles.buttons}>
                    <Button type="primary" htmlType="submit" loading={isLoading} >
                        {t('submit', {ns: "common"})}
                    </Button>
                    <Button htmlType="button" onClick={onCancel}>
                        {t('cancel', {ns: "common"})}
                    </Button>
                </Input.Group>
            </Form.Item>
        </Form>
    )
}

export {AdventureForm}