import React, {ReactNodeArray, useCallback, useEffect, useRef, useState} from 'react'
import GoogleMapReact, {Coords} from 'google-map-react'
import {createMarker} from "@packages/components/geo/marker";
import styles from "@packages/components/geo/google-map.module.less";

import {useLocalStorage} from "usehooks-ts";
import mapStyles from '@georiddle/app/src/components/map-styles'
import {centerPoints, GeoLocation} from "@packages/commons/src/geo";
import {locationRef} from "@packages/commons/src/geo/track";
import {useLocation} from "@packages/components/hooks/useLocation";
import {Spinner} from "@packages/components/common/spinner";
import {GlobalOutlined} from "@ant-design/icons";
import clsx from "clsx";
import {ReactComponent as Location} from "@packages/components/icons/map/location.svg";
import {ReactComponent as Gps} from "@packages/components/icons/map/gps.svg";
import {ReactComponent as MapType} from "@packages/components/icons/map/type.svg";
import {IS_PRODUCTION} from "@packages/commons/src/env";
import { motion, AnimatePresence } from "framer-motion/dist/framer-motion"
import {play, SOUND} from "@packages/commons/src/sound";

const MAPS_API_KEY = 'AIzaSyDakN5f5Khi9frfpIXH5ReMQTEXGnR-P6Q'
export const DEFAULT_CENTER = {
    lat: 49.947470,
    lng: 20.392005,
}
const DEFAULT_ZOOM = 14

type MapProps = {
    children?: ReactNodeArray | React.ReactNode
    onLoad?: (map: google.maps.Map) => void
    defaultZoom?: number
    showLocation?: boolean
    onLocationClick?: () => void
}


interface LocationMarkerProps extends Coords {

}

const LocationMarker = createMarker<LocationMarkerProps>((props) => {
    return (
        <Location/>
    )
})

const GoogleMap = ({
                       children, onLoad, defaultZoom = DEFAULT_ZOOM, showLocation = true, onLocationClick = () => {
    }
                   }: MapProps) => {
    const currentLocation = useLocation(2000)
    const [type, setMapType] = useLocalStorage<string>('google-map-type', 'roadmap')
    const mapRef = useRef<google.maps.Map | null>(null)

    const createMapOptions = useCallback(() => {
        return {
            mapId: "2b7c26a5e44f6624",
            mapTypeId: type,
            controlSize: 20,
            panControl: false,
            mapTypeControl: false,
            rotateControl: true,
            scrollWheel: true,
            zoomControl: false,
            fullscreenControl: false,
        }
    }, [])

    const apiLoadedCallback = useCallback(
        ({map}) => {
            onLoad?.(map)
            mapRef.current = map
        },
        [onLoad]
    )

    const setMapTypeCallback = () => {
        const newType = type === 'roadmap' ? 'satellite' : 'roadmap'
        setMapType(newType)
        mapRef.current?.setMapTypeId(newType);
    }

    if (!currentLocation && IS_PRODUCTION) {
        return <Spinner centered/>
    }

    return (
        <>
            <GoogleMapReact
                bootstrapURLKeys={{
                    key: MAPS_API_KEY,
                    region: 'pl',
                    language: 'pl',
                    libraries: ['geometry'],
                }}
                onMapTypeIdChange={(type) => {
                    setMapType(type)
                }}
                defaultCenter={currentLocation || DEFAULT_CENTER}
                defaultZoom={defaultZoom}
                yesIWantToUseGoogleMapApiInternals
                options={createMapOptions}
                onGoogleApiLoaded={apiLoadedCallback}
            >
                {children}
                {
                    showLocation && currentLocation &&
                    <LocationMarker {...currentLocation} />
                }
            </GoogleMapReact>
            <motion.div whileTap={{ scale: 0.8}} onClick={()=> {
                setMapTypeCallback()
                play(SOUND.TAP)
            }}
                 className={clsx(type === 'satellite' && styles.selectedIcons, styles.globalIcons, styles.moveTop)}>
                <MapType/>
            </motion.div>
            <motion.div whileTap={{ scale: 0.8}} onClick={()=> {
                onLocationClick()
                play(SOUND.TAP)
            }} className={clsx(styles.selectedIcons, styles.globalIcons)}>
                <Gps/>
            </motion.div>
        </>
    )
}

export default GoogleMap
