import React, {useCallback, useRef, useState} from 'react'

import styles from './check-prize.module.less'
import {useTranslation} from "react-i18next";
import {Alert, Button, Form, Input, notification} from "antd";

import {getOrg, useUser} from "@hooks/useFirestore";
import {useBreakpoint} from "@packages/components/hooks/useBreakpoint";
import {getSingle, setSingle, updateSingle} from "@packages/commons/src/services/firebase";
import {Prize} from "@packages/types/domain";
import {getPrizeId} from "@packages/commons/src/domain";

type CheckPrizeProps = {}

const CheckPrize = ({}: CheckPrizeProps) => {
    const user = useUser()
    const {t} = useTranslation()
    const {isSm} = useBreakpoint("sm")
    const [validCode, setValidCode] = useState<string | null>(null)
    const prizeRef = useRef<Prize | null>()
    const [updating, setUpdating] = useState(false)

    const markAsUsed = useCallback(async () => {
        if (!prizeRef.current) {
            return
        }
        setUpdating(true)
        try {
            await updateSingle<Prize>(`prizes/${prizeRef.current.id}`, {
                ...prizeRef.current,
                used: true,
            })
            setValidCode(null)
        } finally {
            setUpdating(false)
        }
    }, [])

    const onSubmit = async (data: any) => {
        const prize = await getSingle<Prize>(`prizes/${getPrizeId(user.data?.organizationId!, data.code)}`)
        prizeRef.current = prize

        if (!prize) {
            notification.error({message: t('check_prize.not_exists')})
            return
        }

        // @ts-ignore
        if (prize.expiresAt.seconds * 1000 < new Date().getTime()) {
            notification.error({message: t('check_prize.expires')})
            return
        }

        if (prize.used) {
            notification.error({message: t('check_prize.already_used')})
            return;
        }
        setValidCode(prize.code)
    }

    return (
        <div>
            <h2>
                {t('check_prize.header')}
            </h2>
            {
                !validCode &&
                <Form
                    initialValues={{}}
                    onFinish={onSubmit}
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 10 }}
                    layout="horizontal"
                >
                    <Form.Item colon={false} label={isSm ? " " : undefined} >
                        <Alert message={t('check_prize.description')} type="info" showIcon />
                    </Form.Item>
                    <Form.Item rules={[{required: true}]} label={t('code', {ns: "common"})} name="code">
                        <Input />
                    </Form.Item>
                    <Form.Item colon={false} label={isSm ? " " : undefined}>
                        <Input.Group className={styles.buttons}>
                            <Button type="primary" htmlType="submit" >
                                {t('check', {ns: "common"})}
                            </Button>
                        </Input.Group>
                    </Form.Item>
                </Form>
            }
            {
                validCode &&
                <div className={styles.codeGroup}>
                    <Alert message={t('valid_code')} type="success" showIcon />
                    <div className={styles.codeButtons}>
                        <Button loading={updating} onClick={() => setValidCode(null)}>
                            {t('back', {ns: "common"})}
                        </Button>
                        <Button loading={updating} type="primary" onClick={markAsUsed} >
                            {t('check_prize.mark_as_used')}
                        </Button>
                    </div>
                </div>
            }
        </div>
    )
}

export {CheckPrize}