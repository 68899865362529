import {Layout} from "antd";
import styles from "./layout.module.less";
import React from "react";
import {OrganizationChecker} from "@components/common/organization-checker";
import {Outlet} from "react-router-dom";

const AppContentLayout = () => {
    return (
        <Layout.Content className={styles.root}>
            <div className={styles.container}>
                <OrganizationChecker>
                    <Outlet />
                </OrganizationChecker>
            </div>
        </Layout.Content>
    )
};

export { AppContentLayout }
