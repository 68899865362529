import "typeface-roboto";
import './styles/global.less'

import React, {ReactNode} from "react";
import ReactDOM from "react-dom";
import {App} from "@components/app";

import {RecoilRoot, useRecoilState} from "recoil";
import {BrowserRouter} from "react-router-dom";
import {QueryClientProvider} from 'react-query'

import AuthWrapper from "@components/auth";
import i18n, {init} from './i18n/config';
import {ConfigProvider} from "antd";
import {initFirebase} from "@packages/commons/src/services/firebase";
import {queryClient} from "@packages/commons/src/services/react-query";
import {selectedLanguageAtom} from "./atoms";
import {withSentry} from "@packages/components/services/sentry";

init()


const SmartConfigProvider = ({children}: {children: ReactNode}) => {
    const [_] = useRecoilState(selectedLanguageAtom)
    const validateMessages = {
        'required': i18n.t('common.required_field')
    }
    return <ConfigProvider form={{validateMessages}}>
        {children}
    </ConfigProvider>
}

const AppContainer = () => {
    return (
        <React.StrictMode>
            <BrowserRouter>
                <RecoilRoot>
                    <QueryClientProvider client={queryClient} >
                        <AuthWrapper>
                            <SmartConfigProvider>
                                <App />
                            </SmartConfigProvider>
                        </AuthWrapper>
                    </QueryClientProvider>
                </RecoilRoot>
            </BrowserRouter>
        </React.StrictMode>
    )
}

const AppWithSentry = withSentry(AppContainer, 'https://d52351a2b1a94f23b3429a492de9cce0@o1375284.ingest.sentry.io/6683519')

initFirebase().then(() => {
    ReactDOM.render(
        <AppWithSentry />,
        document.getElementById("root")
    );
})
