import Toolbar from "./toolbar/toolbar";
import {Button, Layout, Result} from "antd";
import {Link, Navigate, Route, Routes, useNavigate} from 'react-router-dom';

import styles from './app.module.less'

import {AppContentLayout} from "@components/common/layout";
import {CreateAdventure} from "@components/screens/adventure/create-adventure";
import {SingleAdventure} from "@components/screens/adventure/single-adventure";
import {Adventures} from "@components/screens/adventure/adventures";
import {EditAdventure} from "@components/screens/adventure/edit-adventure";
import {CreateRiddle} from "@components/screens/riddle/create-riddle";
import {EditRiddle} from "@components/screens/riddle/edit-riddle";
import {QrCodes} from "@components/screens/qr-codes/qr-codes";
import {ROUTES} from "../routes";
import {NotFound} from "@packages/components/status/not-found";
import {FindRiddle} from "@components/screens/find-riddle/find-riddle";
import {CheckPrize} from "@components/screens/check-prize/check-prize";

export const App = () => {
    return (
        <Routes>
            <Route path="*" element={<Layout className={styles.root}>
                <Toolbar />
                <Routes>
                    <Route path="/" element={<Navigate to="/adventures" replace />} />
                    <Route element={<AppContentLayout />}>
                        <Route path={`/${ROUTES.QR_CODES}`} element={<QrCodes />} />
                        <Route path={`/${ROUTES.FIND_RIDDLE}`} element={<FindRiddle />} />
                        <Route path={`/${ROUTES.CHECK_PRIZE}`} element={<CheckPrize />} />
                        <Route path="/adventures" >
                            <Route index element={<Adventures/>} />
                            <Route path="create" element={<CreateAdventure />} />
                            <Route path=":adventureId/riddles/create" element={<CreateRiddle />} />
                            <Route path=":adventureId/riddles/:riddleId/edit" element={<EditRiddle />} />
                            <Route path=":adventureId/riddles/:riddleId" element={<SingleAdventure />} />
                            <Route path=":adventureId/edit" element={<EditAdventure />} />
                            <Route path=":adventureId" element={<SingleAdventure />} />
                        </Route>
                        <Route path="*" element={<NotFound />} />
                    </Route>
                </Routes>
            </Layout>} />
        </Routes>
      );
};
