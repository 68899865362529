import {useTranslation} from "react-i18next";

import React from "react";
import {useSetAdventure} from "@hooks/useFirestore";
import {createId, undefinedToNull} from "@packages/commons/src/object";
import {AdventureForm, AdventureFormResult} from "./adventure-form";
import {ROUTES} from "../../../routes";
import {useNavigate} from "react-router-dom";

type CreateAdventureProps = {

}

const CreateAdventure = ({  }: CreateAdventureProps) => {
    const { t } = useTranslation()
    const mutation = useSetAdventure()
    const navigate = useNavigate();

    const onSubmit = (data: AdventureFormResult) => {
        mutation.mutate({
            ...undefinedToNull(data),
            id: createId(data.name),
            riddles: [],
        })
        navigate(`/${ROUTES.ADVENTURES}`)
    }

    const onCancel = () => {
        navigate(`/${ROUTES.ADVENTURES}`)
    }

    return (
        <div>
            <h2>{t('create_adventure.header')}</h2>
            <AdventureForm isLoading={mutation.isLoading} onSubmit={onSubmit} onCancel={onCancel} />
        </div>
    )
}

export { CreateAdventure }