import {Adventure} from "@packages/types/domain";
import React from "react";
import {useParams} from "react-router-dom";
import {useAdventure} from "@hooks/useFirestore";
import {Spinner} from "@packages/components/common/spinner";

export type WithAdventureProps = {
    adventure: Adventure
}

export const withAdventure = <T extends WithAdventureProps>(Component: React.ComponentType<T>) => (props: Omit<T, keyof WithAdventureProps>) => {
    let { adventureId } = useParams();
    const adventure = useAdventure(adventureId)

    if (adventure.isLoading) {
        return <Spinner centered />
    }
    if (!adventure.data) {
        return <div>No data</div>
    }

    return <Component {...(props as T)} adventure={adventure.data!} />
}