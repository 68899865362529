import React, {useEffect} from 'react'

import styles from './find-riddle.module.less'
import {useAdventures} from "@hooks/useFirestore";
import {Spinner} from "@packages/components/common/spinner";
import {useQrScanner} from "@packages/components/qr-scanner";
import {findRiddleByCode, getCodeFromUrl} from "@packages/commons/src/domain";
import {Button, notification} from "antd";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {ScanOutlined} from "@ant-design/icons";
import {Centered} from "@packages/components/layout/centered";
import {SuccessButton} from "@packages/components/common/success-button";
import {BackButton} from "@packages/components/common/back-button";

type FindRiddleProps = {}

const FindRiddle = ({}: FindRiddleProps) => {
    const adventures = useAdventures()
    const {t} = useTranslation()
    const navigate = useNavigate()

    const {scan, Scanner, show, cancel}  = useQrScanner((data) => {
        if (!data || !adventures.data) {
            return;
        }

        try {
            const code = getCodeFromUrl(data)
            const {riddle, adventure} = findRiddleByCode(code, adventures.data);

            if (!riddle) {
                notification.info({message: t('riddle_dont_exists')});
                return
            }
            navigate(`/adventures/${adventure?.id}/riddles/${riddle.id}/edit`)
        } catch (e) {
            notification.error({message: t('wrong_scanned_code_error')});
        }
    }, {fullScreen: false})

    // useEffect(() => {
    //     scan();
    // } ,[scan])

    if (adventures.isLoading) {
        return <Spinner centered />
    }

    return (
        <div className={styles.root}>
            <h2>
                {t('find_riddle.header')}
            </h2>
            {
                show &&
                <Scanner />
            }
            <div className={styles.buttonContainer}>
            {
                !show
                    ? <Button type="primary" icon={<ScanOutlined />} onClick={() => scan()}>{t('scan', {ns: "common"})}</Button>
                    : <Button onClick={cancel}>{t('cancel', {ns: 'common'})}</Button>
            }
            </div>
        </div>
    )
}

export {FindRiddle}