import React, {FunctionComponent, SVGProps, useEffect} from 'react'

import styles from './riddle-marker.module.less'
import {Riddle, RiddleType} from "@packages/types/domain";
import {Coords} from "google-map-react";
import {createMarker} from "@packages/components/geo/marker";
import clsx from "clsx";

import {ReactComponent as Checked} from "@packages/components/icons/checked.svg";
import {ReactComponent as QrCode} from "@packages/components/icons/map/qr-code.svg";
import {ReactComponent as Place} from "@packages/components/icons/map/place.svg";
import {ReactComponent as Code} from "@packages/components/icons/map/code.svg";

import { motion } from "framer-motion/dist/framer-motion"

export type MapRiddle = Pick<Riddle, "id" | "radius"| "type"> & {finished: boolean} & Coords

export type RiddleMarkerProps = Coords & {
    map?: google.maps.Map | null
    radius?: number
    riddle: MapRiddle
    selected?: boolean
    onClick?: (id: string) => void
    onRadiusClick?: (event: unknown) => void
}

const TypeToComponentMapping: {[key in RiddleType]: FunctionComponent<SVGProps<SVGSVGElement>>} = {
    'text_code': Code,
    'qr_code': QrCode,
    'place': Place,
}

export const RiddleMarker = createMarker<RiddleMarkerProps>((props) => {
    const Icon =  TypeToComponentMapping[props.riddle.type]

    useEffect(() => {
        if (!props.map || !props.radius) {
            return
        }

        const circle = new google.maps.Circle({
            strokeColor: "#F4954C",
            strokeOpacity: 0.6,
            strokeWeight: 2,
            fillColor: "#F4954C",
            fillOpacity: 0.1,
            map: props.map,
            center: {
                ...props
            },
            radius: props.radius
        });

        const listener = google.maps.event.addListener(circle, 'click', (function (event: any) {
            props.onRadiusClick?.(event)
        }))

        return () => {
            circle.setMap(null);
            google.maps.event.removeListener(listener);
        }
    }, [props.map, props.radius, props.lng, props.lat])

    const finished = props.riddle.finished;

    return (
        <motion.div
            initial={{ opacity: 0.7, y: -200 }}
            animate={{ opacity: 1, y:0 }}
            transition={{ duration: 0.5, type: "spring" }}
            className={clsx(styles.riddleMarker, props.selected && styles.selected, finished && styles.finished)} onClick={() => {
            props.onClick?.(props.riddle.id)
        }}>
            <Icon />
            {
                finished &&
                <Checked className={styles.checked} />
            }
        </motion.div>
    )
})