import {useMutation, UseMutationOptions, useQuery} from "react-query";

import useFirebaseUser from "@hooks/useFirebaseUser";
import {User} from "../types/domain";
import {Adventure, Organization} from "@packages/types/domain";
import {queryClient} from "@packages/commons/src/services/react-query";
import {deleteSingle, getMultiple, getSingle, setSingle, updateSingle} from "@packages/commons/src/services/firebase";

export const useUser = () => {
    const user = useFirebaseUser()
    if (!user?.email) {
        throw 'Firebase user doesn\'t exists'
    }
    return useQuery('user', () => {
        return getSingle<User>(`users/${user?.email!}`)
    })
}

export const useSetUser = () => {
    const updateUser = async (user: User) => {
        await setSingle(`users/${user.email}`, user)
        return user
    }

    return useMutation(updateUser, {
        onSuccess: data => {
            queryClient.setQueryData(['user'], data)
        }
    })
}

type OrganizationUpdate = Organization

export const useSetOrganization = () => {
    const updateOrganization = async (organization: OrganizationUpdate) => {
        await setSingle(`organizations/${organization.id}`, organization)
        return organization
    }

    return useMutation(updateOrganization, {
        onSuccess: () => {
            queryClient.invalidateQueries(['organization'])
        }
    })
}

type OrganizationCodeUpdate = {
    code: string,
    adventure: string,
    riddle: string
    remove?: boolean
}

export const useSetOrganizationCode = () => {
    const user = useUser()

    const updateOrganization = async (update: OrganizationCodeUpdate) => {
        const {code, adventure, riddle, remove = false} = update
        const updateObject = {
            [`qrCodes.${code}`]: remove ?  null : `${adventure}/${riddle}`
        }
        await updateSingle(`organizations/${user.data?.organizationId}`, updateObject)
        return update
    }

    return useMutation(updateOrganization, {
        onSuccess: () => {
            queryClient.invalidateQueries(['organization'])
        }
    })
}

export const getOrg = (name: string) =>
    getSingle<Organization>(`organizations/${name}`)

export const useOrganization = () => {
    const user = useUser()
    return useQuery('organization', () => {
        return getOrg(user.data?.organizationId!)
    }, { enabled: !!user.data?.organizationId })
}

export const useAdventures = () => {
    const user = useUser()
    return useQuery('adventures', () => {
        return getMultiple<Adventure>(`organizations/${user.data?.organizationId}/adventures`)
    }, { enabled: !!user.data?.organizationId })
}

export const useSetAdventure = () => {
    const user = useUser()
    const updateAdventure = async (adventure: Partial<Adventure> & {id: string}) => {
        const path = `organizations/${user.data?.organizationId}/adventures/${adventure.id}`
        await setSingle(path, adventure)
        return adventure
    }
    return useMutation(updateAdventure, {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['adventure', data.id])
            queryClient.invalidateQueries('adventures', { exact: true })
        }
    })
}

export const getAdventure = (orgId: string, adventureId: string | undefined) =>
    getSingle<Adventure>(`organizations/${orgId}/adventures/${adventureId}`)

export const useAdventure = (id: string | undefined) => {
    const user = useUser()
    return useQuery(['adventure', id],
        () => getAdventure(user.data?.organizationId!, id),
        { enabled: (!!user.data?.organizationId && !!id) }
    )
}

export const useDeleteAdventure = () => {
    const user = useUser()
    const updateAdventure = async (id: string) => {
        const path = `organizations/${user.data?.organizationId}/adventures/${id}`
        await deleteSingle(path)
        return id
    }

    return useMutation(updateAdventure, {
        onSuccess: (id) => {
            queryClient.invalidateQueries(['adventure', id])
            queryClient.invalidateQueries('adventures', { exact: true })
        }
    })
}