import {Avatar, Button, Dropdown, Layout, Segmented} from "antd";
import {FunctionComponent, useEffect} from "react";
import { ReactComponent as LogoIcon } from '@packages/components/icons/logo.svg';
import UserMenu from './user-menu/user-menu';

import styles from './toolbar.module.less';
import useFirebaseUser from "@hooks/useFirebaseUser";
import {MenuOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import {useLocalStorage} from "usehooks-ts";
import i18n from '../../i18n/config';
import { useSetRecoilState } from "recoil";
import {selectedLanguageAtom} from "../../atoms";
import {useOrganization} from "@hooks/useFirestore";

const browserLang = () => {
    // @ts-ignore
    const lang = (window.navigator.userLanguage || window.navigator.language);
    if (lang) {
        return lang.slice(0,2)
    }
    return ''
}

const LanguageSwitch = ({className}: {className: string}) => {
    const [lang, setLang] = useLocalStorage<string>('userlang', browserLang())
    const setLangAtom = useSetRecoilState(selectedLanguageAtom)

    const onLangChange = (value: string| number) => {
        const newLang = value.toString().toLowerCase()
        if (newLang !== lang) {
            setLang(newLang)
            i18n.changeLanguage(newLang)
        }
    }

    useEffect(() => {
        if (lang !== i18n.language) {
            i18n.changeLanguage(lang)
        }
    }, [])

    useEffect(() => {
        setLangAtom(lang)
    }, [lang])

    return (
        <Segmented className={className} size="small" value={lang.toUpperCase()} onChange={onLangChange} options={['EN', 'PL']} />
    )
}
 
const Toolbar: FunctionComponent<{}> = () => {
    const user = useFirebaseUser()
    const { t } = useTranslation()

    return (
        <Layout.Header className={styles.root}>
            <section className={styles.logoContainer}>
                <LogoIcon className={styles.logo} />
                GEO RIDDLE
            </section>
            <section>
                <LanguageSwitch className={styles.langSwitch} />
                <span className={styles.user}>{user && user.email}</span>
                <Dropdown overlay={<UserMenu/>} trigger={['click']}>
                    <div className={styles.hamburger}><MenuOutlined /></div>
                </Dropdown>
            </section>

        </Layout.Header>
    );
};
 
export default Toolbar;