import React, {useEffect, useMemo, useState} from 'react'

import {Coords} from "google-map-react";
import {locationRef} from "@packages/commons/src/geo/track";
import {calcDistance} from "@packages/commons/src/geo";
import {useLocation} from "@packages/components/hooks/useLocation";
import {ReactComponent as LogoIcon} from './../icons/logo.svg';

import styles from './distance.module.less'
import {Spinner} from "@packages/components/common/spinner";

type DistanceProps = {} & Coords

const Distance = (props: DistanceProps) => {
    const location = useLocation()

    const distance = useMemo(() => {
        return location && Math.round(calcDistance(props, location))
    }, [location, props])

    return (
        <div className={styles.root}>
            {
                distance
                    ? <><LogoIcon className={styles.distanceIcon}/>
                        {
                            distance &&
                            <span>{`${distance}m`}</span>
                        }
                    </>
                    : <Spinner tipMode="only-label"/>
            }
        </div>
    )
}

export {Distance}