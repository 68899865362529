import React, {useCallback, useEffect, useState} from 'react'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import styles from './editor.module.less'
import clsx from "clsx";

const modules = {
    toolbar: [
        // [],// { 'header': '1'}, {'header': '2'}, { 'font': [] }
        // [{size: []}],
        ['bold', 'italic', 'underline'],
        [{'list': 'ordered'}, {'list': 'bullet'},
            {'indent': '-1'}, {'indent': '+1'}],
        [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
        // ['image', 'video'],
        // ['clean']
    ],
    clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
    }
}
/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
const formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'video', 'align'
]

type EditorProps = {
    value?: string
    onChange?: (val: string) => void
    className?: string
}

const Editor = ({value = '', onChange, className}: EditorProps) => {
    return (
        <div className={clsx(styles.root, className)}>
            <ReactQuill modules={modules}
                        formats={formats} theme="snow" value={value} onChange={onChange}/>
        </div>
    );
}

export {Editor}