import React, {useCallback, useEffect, useRef, useState} from 'react'

import styles from './photo-input.module.less'
import {
    DialogApi,
    FileInfo,
    FilesUpload,
    FileUpload,
    Locale,
    Panel,
    PanelAPI,
    PanelProps,
    Widget
} from "@uploadcare/react-widget";
import {useRecoilState} from "recoil";
import {selectedLanguageAtom} from "../../atoms";

type PhotoInputWrapperProps = {
    value?: string | null
    onChange?: (val: string | null) => void
}

export const PhotoInputWrapper = ({value, onChange}: PhotoInputWrapperProps) => {
    const onChangeCallback = useCallback((newValue: string | null) => {
        if (newValue != value) {
            onChange?.(newValue)
        }
    }, [value, onChange])

    if (!onChange) {
        return <></>;
    }

    return <PhotoInput defaultValue={value || undefined} onChange={onChangeCallback} />
}

type PhotoInputProps = {
    defaultValue?: string | undefined
    onChange: (val: string | null) => void
}

const PhotoInput = ({defaultValue, onChange}: PhotoInputProps) => {
    const [lang] = useRecoilState(selectedLanguageAtom)
    const locale = lang === 'pl' ? 'pl' : lang === 'en' ? 'en' : undefined

    return (
        <Widget  imageShrink="1600x1200" locale={locale} tabs="file camera" publicKey="e9ab171c3af3d324fd09" value={defaultValue} clearable multiple imagesOnly
                onFileSelect={(e) => {
                    if (!e) {
                        onChange(null)
                        return
                    }
                    if (e) {
                        const f = e as FilesUpload
                        f.promise().done((res) => {
                            onChange(res.uuid)
                        })
                    }
                }}

        />
    )
}

export {PhotoInput}

