import { Button } from 'antd'
import React from 'react'

import styles from './back-button.module.less'
import {ButtonProps} from "antd/lib/button/button";
import clsx from "clsx";

type BackButtonProps = Omit<ButtonProps, "type">

import { motion } from "framer-motion/dist/framer-motion"

const BackButton = ({className, block, ...rest}: BackButtonProps) => {
    return (
        <motion.div whileTap={{ scale: 0.8}} className={clsx(styles.container,block && styles.block)}>
            <Button block={block} {...rest} className={clsx(styles.root, className)} />
        </motion.div>
    )
}

export {BackButton}