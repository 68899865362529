import React, {ReactNode, useEffect, useState} from "react";
import {Route, Routes, useNavigate} from "react-router-dom";
import {Auth, EmailAuthProvider, getAuth, GoogleAuthProvider, signOut} from "firebase/auth";
import FirebaseUIAuth from "react-firebaseui-localized";

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

import './auth.less'
import styles from './auth.module.less'
import {useTranslation} from "react-i18next";
import {WelcomeScreen} from "@packages/components/layout/welcome-screen";
import {Spinner} from "@packages/components/common/spinner";
import {PageHeader} from "@packages/components/typography";

const ENABLE_VERIFICATION = false

// Configure FirebaseUI.
const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    callbacks: {
        // Avoid redirects after sign-in.
        signInSuccessWithAuthResult: () => false,
    },
    // We will display Google and Facebook as auth providers.
    signInOptions: [
        {
            provider: EmailAuthProvider.PROVIDER_ID,
            // Use email link authentication and do not require password.
            // Note this setting affects new users only.
            // For pre-existing users, they will still be prompted to provide their
            // passwords on sign-in.
            signInMethod: EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD,
            // Allow the user the ability to complete sign-in cross device, including
            // the mobile apps specified in the ActionCodeSettings object below.
            forceSameDevice: false,
            // Used to define the optional firebase.auth.ActionCodeSettings if
            // additional state needs to be passed along request and whether to open
            // the link in a mobile app if it is installed.
        },
        GoogleAuthProvider.PROVIDER_ID,
    ],
};

type AuthWrapperProps = {
    children: ReactNode,
}

enum USER_STATE {
  LOGGED_IN = 'LOGGED_IN',
  LOGGED_OUT = 'LOGGED_OUT',
  NOT_VERIFIED = 'NOT_VERIFIED',
  AUTH_ACTION = 'AUTH_ACTION',
}

let authInstance: Auth | null = null

export default function AuthWrapper({children}: AuthWrapperProps) {
    const [firebaseInitialized, setFirebaseInitialized] = useState(false);
    const [userState, setUserState] = useState(USER_STATE.LOGGED_OUT); // Local signed-in state.
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        const auth = getAuth()
        authInstance = auth
        const unregisterAuthObserver = auth.onAuthStateChanged(user => {
            const isSignIn = window.location.pathname === '/sign-in';
            const isAuthAction = window.location.pathname === '/auth-action';
            setFirebaseInitialized(true)
            if (isAuthAction) {
                setUserState(USER_STATE.AUTH_ACTION)
            } else if (!user) {
                setUserState(USER_STATE.LOGGED_OUT)
                if (!isSignIn) {
                    navigate('/sign-in')
                }
            } else if (ENABLE_VERIFICATION && !user.emailVerified) {
                setUserState(USER_STATE.NOT_VERIFIED)
                //TODO: make sure we don't send verification too many times
                // user?.sendEmailVerification()
            } else if (user) {
                setUserState(USER_STATE.LOGGED_IN)
                // setupAuth()
                isSignIn && navigate('/')
            }
        });
        return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
    }, []);

    if (!firebaseInitialized) {
        return (<WelcomeScreen className={styles.centered}><Spinner /></WelcomeScreen>)
    }

    if (userState === USER_STATE.NOT_VERIFIED) {
        return <WelcomeScreen className={styles.centered}><h4>{t('sigin.check_email')}</h4></WelcomeScreen>
    }

    if (userState === USER_STATE.LOGGED_OUT || userState === USER_STATE.AUTH_ACTION) {
        return (
            <WelcomeScreen>
                <main>
                    <PageHeader size="very-large"><a href="/sign-in">Georiddle</a></PageHeader>
                    <Routes>
                        <Route path="/sign-in" element={(<h4>{t('signin.header')}</h4>)} />
                    </Routes>
                    <FirebaseUIAuth
                        lang="pl"
                        rtl={false}
                        version="4.7.3"
                        config={uiConfig}
                        auth={authInstance}
                        firebase={firebase}
                    />
                </main>
            </WelcomeScreen>
        );
    }
    return <>{children}</>;
}

export const logout = () => signOut(authInstance!)

export { authInstance }