import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";

import styles from './pick-location-map.module.less'
import GoogleMap from "@packages/components/geo/google-map";
import {locationRef} from "@packages/commons/src/geo/track";
import {centerPoints, getBounds} from "@packages/commons/src/geo";
import {MapRiddle, RiddleMarker} from "@packages/components/geo/riddle-marker";
import {Coords} from "google-map-react";
import {Form, FormInstance} from "antd";
import {RiddleFormResult} from "@components/screens/riddle/riddle-form";
import useElementSize from "@packages/components/hooks/useElementSize";
import {Nullable} from "@packages/types/domain";

export type PickLocationMap = {
    value?: Coords
    onChange: (coords: Coords) => void
    radius?: Nullable<number>,
}

type PickLocationMapWrapperProps = {
    form: FormInstance<RiddleFormResult>
}

export const PickLocationMapWrapper = ({form}: PickLocationMapWrapperProps) => {
    const lat = Form.useWatch('lat', form);
    const lng = Form.useWatch('lng', form);
    const radius = Form.useWatch('radius', form);

    const onChangeCallback = useCallback((newValue: Coords) => {
        form.setFieldsValue({
            lat: newValue.lat,
            lng: newValue.lng,
        })
    }, [form])

    const value = (lat && lng) ? {lat, lng} : undefined
    return <PickLocationMap radius={radius} value={value} onChange={onChangeCallback} />
}

const PickLocationMap = ({value, onChange, radius}: PickLocationMap) => {
    const containerRef = useRef<HTMLDivElement>(null!);
    const { width, height } = useElementSize(containerRef)
    const [map, setMap] = useState<google.maps.Map | null>(null)

    useEffect(() => {
        if (map === null) {
            return
        }
        const locationPoints = locationRef.current ? [locationRef.current] : []
        const points = value ? [value] : locationPoints
        centerPoints(map, points, { width, height })
    }, [map])

    const handleEvent = useCallback((event) => {
        onChange({
            lat: event.latLng.lat(),
            lng: event.latLng.lng(),
        })
    }, [])

    const onMapLoaded = useCallback((mapInstance) => {
        setMap(mapInstance)
        google.maps.event.addListener(mapInstance, 'click', handleEvent);
    }, [])

    const marker = value ?
        <RiddleMarker selected={true} map={map} onRadiusClick={handleEvent} radius={radius || 0} lat={value.lat} lng={value.lng} riddle={{lat: value.lat, lng: value.lng, id: '', type: "qr_code", finished: false }} />
        : null

    return (
        <div className={styles.root} ref={containerRef}>
            {
                width && height &&
                <GoogleMap showLocation={false} onLoad={onMapLoaded}>
                    {
                        !map ? null : marker
                    }
                </GoogleMap>
            }
        </div>
    );
};
