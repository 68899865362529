import React, {useMemo} from 'react'
import {useTranslation} from "react-i18next";
import {useAdventure, useSetAdventure, useSetOrganizationCode, useUser} from "@hooks/useFirestore";
import {createId, undefinedToNull} from "@packages/commons/src/object";
import {useNavigate, useParams} from "react-router-dom";
import {Spinner} from "@packages/components/common/spinner";
import {ROUTES} from "../../../routes";
import {RiddleForm, RiddleFormResult} from "@components/screens/riddle/riddle-form";
import {withAdventure, WithAdventureProps} from "@components/common/withAdventure";
import useFirebaseUser from "@hooks/useFirebaseUser";
import {modifyOrgCodes} from "@packages/commons/src/domain";

type EditRiddleProps = {} & WithAdventureProps

const EditRiddle = withAdventure(({adventure}: EditRiddleProps) => {
    const user = useUser()
    const {t} = useTranslation();
    let { riddleId } = useParams();
    const mutation = useSetAdventure()
    const organizationCodeMutation = useSetOrganizationCode()
    const navigate = useNavigate();

    const initialValues = useMemo(() => {
        return (adventure.riddles || []).find(riddle => riddle.id === riddleId)
    }, [])

    const onSubmit = async (data: RiddleFormResult) => {
        const riddle = {
            ...undefinedToNull(data),
            id: riddleId!
        }

        const riddles = (adventure.riddles || []).map(r => r.id === riddleId ? {...r, ...riddle} : r)
        try {
            await mutation.mutate({
                ...adventure,
                riddles
            })
            if (modifyOrgCodes(riddle.type, data.code)) {
                await organizationCodeMutation.mutate({code: data.code!, adventure: adventure.id, riddle: riddleId!})
            }
            if (initialValues?.type === 'qr_code' && initialValues?.code && initialValues?.code !== data.code) {
                await organizationCodeMutation.mutate({code: initialValues.code, adventure: adventure.id, riddle: riddleId!, remove: true})
            }
        } catch (e) {
        }
        navigate(`/${ROUTES.ADVENTURES}/${adventure.id}`)
    }

    const onCancel = () => {
        navigate(`/${ROUTES.ADVENTURES}/${adventure.id}`)
    }

    const validateName = (name: string) => {
        const riddle = adventure.riddles.find(riddle => createId(name) === riddle.id)
        return !riddle || riddle.id === riddleId
    }

    return (
        <div>
            <h2>{t('edit_riddle.header')}</h2>
            <RiddleForm validateName={validateName} initialValues={initialValues!} isLoading={mutation.isLoading || organizationCodeMutation.isLoading} onSubmit={onSubmit} onCancel={onCancel} />
        </div>
    )
})

export {EditRiddle}