import React from 'react'

import styles from './edit-adventure.module.less'
import {useTranslation} from "react-i18next";
import {useAdventure, useSetAdventure} from "@hooks/useFirestore";
import {AdventureForm, AdventureFormResult} from "@components/screens/adventure/adventure-form";
import {undefinedToNull} from "@packages/commons/src/object";
import {useNavigate, useParams} from "react-router-dom";
import {Spinner} from "@packages/components/common/spinner";
import {ROUTES} from "../../../routes";
import {withAdventure, WithAdventureProps} from "@components/common/withAdventure";

type EditAdventureProps = {} & WithAdventureProps

const EditAdventure = withAdventure(({adventure}: EditAdventureProps) => {
    const {t} = useTranslation();
    let { adventureId } = useParams();
    const mutation = useSetAdventure()
    const navigate = useNavigate();

    const navigateBack = () => {
        navigate(`/${ROUTES.ADVENTURES}/${adventureId}`)
    }

    const onSubmit = async (data: AdventureFormResult) => {
        await mutation.mutate({
            ...undefinedToNull(data),
            id: adventure.id!,
        })
        navigateBack()
    }

    return (
        <div>
            <h2>{t('edit_adventure.header')}</h2>
            <AdventureForm initialValues={adventure} isLoading={mutation.isLoading} onSubmit={onSubmit} onCancel={navigateBack} />
        </div>
    )
})

export {EditAdventure}