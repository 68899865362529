import React, {useCallback, useEffect, useState} from 'react'

import {Adventure, Riddle, RiddleType} from "@packages/types/domain";
import {useTranslation} from "react-i18next";
import {Button, Form, Input, InputNumber, notification, Select, Dropdown, Menu, MenuProps, Switch, Alert} from "antd";

import styles from './riddle-form.module.less'
import {
    DeleteOutlined,
    EllipsisOutlined,
    EnvironmentOutlined,
    InfoCircleOutlined,
    QrcodeOutlined,
    ScanOutlined
} from "@ant-design/icons";
import {Editor} from "@components/common/editor";
import {PhotoInput, PhotoInputWrapper} from "@components/common/photo-input";
import {useQrScanner} from "@packages/components/qr-scanner";
import {useBreakpoint} from "@packages/components/hooks/useBreakpoint";
import {locationRef, stopTracking, trackLocation} from "@packages/commons/src/geo/track";
import {getCodeFromUrl, isRiddlePublishable} from "@packages/commons/src/domain";

import {Spinner} from "@packages/components/common/spinner";
import {useOrganization} from "@hooks/useFirestore";
import {PickLocationMapWrapper} from "@components/screens/riddle/pick-location-map";
import * as validator from "validator";
import {useTrackLocation} from "@packages/components/hooks/useTrackLocation";
import TextArea from "antd/es/input/TextArea";

export type RiddleFormResult = Riddle

type RiddleFormProps = {
    initialValues?:  Partial<Riddle>
    onSubmit: (adv: RiddleFormResult) => void
    onCancel?: () => void
    isLoading: boolean
    validateName: (name: string) => boolean
}

const DEFAULT_VALUES: RiddleFormProps['initialValues'] = {
    difficulty: "normal",
    name: "",
    type: "qr_code",
    showHint: false,
    placeRadius: 5
}

const RiddleForm = ({initialValues = DEFAULT_VALUES, onSubmit, isLoading, onCancel = () => {}, validateName}: RiddleFormProps) => {
    const {t: tNoPrefix} = useTranslation()
    const {t} = useTranslation(undefined,{keyPrefix: 'riddle_form'})
    const {isSm} = useBreakpoint("sm")
    const [form] = Form.useForm<RiddleFormResult>();
    const [isPublishable, setIsPublishable] = useState<boolean | null>(null)
    const organization = useOrganization()
    const type = Form.useWatch<RiddleType>('type', form);
    const showHint = Form.useWatch<boolean>('showHint', form);

    const {startLocalization, isLocationLoading, locationError, stopTrackingLocation } = useTrackLocation()

    useEffect(() => {
        startLocalization()
        return () => {
            stopTrackingLocation()
        }
    }, [])

    const {scan, Scanner}  = useQrScanner((data) => {
        if (!data) {
            return;
        }
        try {
            const code = getCodeFromUrl(data)
            form.setFieldsValue({
                code
            })
        } catch (e) {
            notification.error({message: t('wrong_scanned_code_error')});
        }
    })

    const checkIsPublishable = useCallback(() => {
        const values = form.getFieldsValue()
        const res = isRiddlePublishable(values)
        setIsPublishable(res)
    }, [form])

    const fillLocation = useCallback(async () => {
        form.setFieldsValue({
            lat: locationRef.current?.lat,
            lng: locationRef.current?.lng,
        })
        checkIsPublishable()
    }, [form, checkIsPublishable])

    const pickNextCode = useCallback(() => {
        const codesMap = organization.data?.qrCodes || {}
        const code = Object.keys(codesMap).find((code) => !codesMap[code])
        if (code) {
            form.setFieldsValue({
                code: code
            })
        } else {
            notification.error({message: t('no_empty_code_error')});
        }
    }, [form, organization.data])

    if (organization.isLoading || !organization.data) {
        return <Spinner centered />
    }

    return (
        <Form
            form={form}
            initialValues={initialValues}
            onFinish={onSubmit}
            onValuesChange={checkIsPublishable}
            labelCol={{span: 8}}
            wrapperCol={{span: 10}}
            layout="horizontal"
        >
            <Form.Item rules={[
                {required: true},
                ({ }) => ({
                    message: t('riddle_already_exists'),
                    validator(_, value) {
                        if (validateName(value)) {
                            return Promise.resolve()
                        } else {
                            return Promise.reject()
                        }
                    }})
            ]}
                       label={t('name')} name="name">
                <Input/>
            </Form.Item>
            <Form.Item label={tNoPrefix('type', {ns: "common"})} name="type">
                <Select>
                    <Select.Option value="qr_code">{tNoPrefix('riddle_type.qr_code', {ns: "common"})}</Select.Option>
                    <Select.Option value="text_code">{tNoPrefix('riddle_type.text_code', {ns: "common"})}</Select.Option>
                    <Select.Option value="place">{tNoPrefix('riddle_type.place', {ns: "common"})}</Select.Option>
                </Select>
            </Form.Item>
            <Form.Item label={tNoPrefix('difficulty', {ns: "common"})} name="difficulty">
                <Select>
                    <Select.Option value="easy">{tNoPrefix('difficulty.easy')}</Select.Option>
                    <Select.Option value="normal">{tNoPrefix('difficulty.normal')}</Select.Option>
                    <Select.Option value="hard">{tNoPrefix('difficulty.hard')}</Select.Option>
                </Select>
            </Form.Item>
            <Form.Item label={tNoPrefix('location', {ns: "common"})} >
                <Input.Group className={styles.location}>
                    <Form.Item
                        key="lat"
                        name='lat'
                        noStyle
                    >
                        <InputNumber style={{ width: '30%' }} controls={false} />
                    </Form.Item>
                    <Form.Item
                        key="lng"
                        name="lng"
                        noStyle
                    >
                        <InputNumber style={{ width: '30%' }} controls={false} />
                    </Form.Item>
                    <Button disabled={!!locationError} onClick={fillLocation} loading={isLocationLoading} icon={<EnvironmentOutlined style={{verticalAlign: 'baseline'}} />}>
                        {t('use_current_location')}
                    </Button>
                </Input.Group>
                <div className={styles.mapContainer}>
                    {
                        form && <PickLocationMapWrapper form={form} />
                    }
                </div>
            </Form.Item>
            <Form.Item name="radius" label={t('radius')} normalize={(value) => {
                const res = parseInt(value)
                return isNaN(res) ? null : res
            }}>
                <Input type="number" allowClear addonAfter={t('meters')} />
            </Form.Item>
            <Form.Item label={tNoPrefix('description', {ns: "common"})}>
                <Form.Item
                    key="description"
                    name="description"
                    noStyle
                >
                    <Editor className={styles.editor} />
                </Form.Item>
                <Form.Item
                    key="photos"
                    name="photos"
                    noStyle
                >
                    <PhotoInputWrapper />
                </Form.Item>
            </Form.Item>
            <Form.Item name="showHint" label={t('hint')}>
                <Form.Item
                    key="showHint"
                    name="showHint"
                    noStyle
                >
                    <Switch defaultChecked={initialValues?.showHint!} className={styles.switch} />
                </Form.Item>
                {
                    showHint &&
                    <>
                        <Form.Item
                            key="hint"
                            name="hint"
                            noStyle
                        >
                            <Editor className={styles.editor} />
                        </Form.Item>
                        <Form.Item
                            key="hintPhotos"
                            name="hintPhotos"
                            noStyle
                        >
                            <PhotoInputWrapper />
                        </Form.Item>
                    </>
                }
            </Form.Item>
            {
                type != 'place' &&
                <Form.Item label={tNoPrefix('code', {ns: "common"})} name="code" >
                    {
                        type === "qr_code" &&
                        <Input.Group className={styles.codeButtons}>
                            <Button icon={<ScanOutlined />} onClick={scan}>{t('scan')}</Button>
                            <Button icon={<QrcodeOutlined />} onClick={pickNextCode}>{t('pick_next')}</Button>
                        </Input.Group>
                    }
                    <Form.Item name="code" dependencies={["type"]} noStyle rules={[
                        ({ getFieldValue }) => ({
                            message: t('invalid_code'),
                            validator(_, value) {
                                const type = getFieldValue('type') as RiddleType
                                if (!value || type === "text_code") {
                                    return Promise.resolve()
                                }
                                const res = Object.keys(organization.data?.qrCodes || {}).includes(value as string)
                                if (res)
                                    return Promise.resolve();
                                else
                                    return Promise.reject('no code')
                            },
                        }),
                    ]} >
                        <Input allowClear />
                    </Form.Item>
                </Form.Item>
            }
            {
                type == 'place' &&
                <Form.Item name="placeRadius" label={t('place_radius')} normalize={(value) => {
                    const res = parseInt(value)
                    return isNaN(res) ? null : res
                }}>
                    <Input type="number" allowClear addonAfter={t('meters')} />
                </Form.Item>
            }
            {
                isPublishable === false && initialValues?.id && form.isFieldsTouched() &&
                <Form.Item label={isSm? " " : undefined} colon={false} >
                    <Alert message={t('not_publishable_warning')} type="warning" showIcon />
                </Form.Item>
            }
            <Form.Item label={isSm? " " : undefined} colon={false}>
                <Input.Group className={styles.buttons}>
                    <Button type="primary" htmlType="submit" loading={isLoading}>
                        {tNoPrefix('submit', {ns: "common"})}
                    </Button>
                    <Button htmlType="button" onClick={onCancel}>
                        {tNoPrefix('cancel', {ns: "common"})}
                    </Button>
                </Input.Group>
            </Form.Item>
            <Scanner />
        </Form>
    )
}

export {RiddleForm}