import React, {useCallback, useMemo, useState} from 'react'

import styles from './qr-codes.module.less'
import {Link} from "react-router-dom";
import {Alert, Button, Divider, Input, InputNumber, Radio, RadioChangeEvent, Slider, Space, Steps, Switch} from "antd";
import {useTranslation} from "react-i18next";
import {useAdventures, useDeleteAdventure, useOrganization} from "@hooks/useFirestore";
import {Spinner} from "@packages/components/common/spinner";
import {QRCodeSVG} from "qrcode.react";
import clsx from "clsx";
import {Title, Label, PageHeader} from "@packages/components/typography";
import {Boxy} from "@packages/components/layout/boxy";

const { Step } = Steps;

type QrCodesProps = {}

type CodeInfoProps = {
    codeKey: string
    onClick: () => void
    value: string,
    isSelected: boolean
}

const CodeInfo = ({codeKey, onClick, value, isSelected} : CodeInfoProps) => {
    const {t} = useTranslation()
    const [adventure, riddle] = (value ? value.split('\/') : [null, null])
    return  <Boxy key={codeKey} onClick={onClick} className={clsx(styles.item,  value && styles.assigned, isSelected && styles.isSelected)} >
        <Title>
            {codeKey}
        </Title>
        {
            <main>
                {
                    !value
                        ? <div>{t('qr_codes.not_assigned')}</div>
                        : <div className={styles.codeAssigned}>
                            <div>{t('adventure', {ns: "common"})}: <span>{adventure}</span></div>
                            <div>{t('riddle', {ns: "common"})}: <span>{riddle}</span></div>
                        </div>
                }
            </main>
        }
    </Boxy>
}

const QrCodes = ({}: QrCodesProps) => {
    const {t} = useTranslation();
    const org = useOrganization()
    const [selected, setSelected] = useState<string[]>([])
    const [size, setSize] = useState(100)
    const [step, setStep] = useState(0)
    const [selectionType, setSelectionType] = useState('empty-codes')
    const [emptyCodesNumber, setEmptyCodesNumber] = useState(10)

    const onItemClick = useCallback((code: string) => {
        if (selected.includes(code)) {
            setSelected(selected.filter(c => c !== code))
        } else {
            setSelected([...selected, code])
        }
    }, [selected])

    const codesMap = org.data?.qrCodes || {}

    const filteredCodes = useMemo(() => {
        return Object.keys(codesMap)
            .filter(code => codesMap[code])
            .sort((a, b) => codesMap[b].localeCompare(codesMap[a]))
    }, [codesMap])

    const selectedCodes = useMemo(() => {
        switch (selectionType) {
            case 'empty-codes':
                return Object.keys(codesMap).filter(code => !codesMap[code]).slice(0, emptyCodesNumber)
            case 'assigned-codes-all':
                return filteredCodes
            case 'assigned-codes-selected':
                return selected
            default:
                return []
        }
    }, [selectionType, selected, emptyCodesNumber, filteredCodes])

    if (org.isLoading || !org.data) {
        return <Spinner centered/>
    }

    return (
        <div className={styles.root}>
            <header>
                <h2>{t('qr_codes.header')}</h2>
            </header>
            <Steps current={step} onChange={setStep}>
                <Step title={t('qr_codes.code_selection')}  />
                <Step title={t('qr_codes.formatting')} />
            </Steps>
            <Alert className={styles.warn} type="info" showIcon
                   message={step === 0 ? t('qr_codes.code_selection_info') : (step === 1 ? <>
                       {t('qr_codes.formatting_info')}
                       <br />
                       {t('qr_codes.warn')}
                       <a href = "mailto: biuro@georiddle.com">biuro@georiddle.com</a>
                   </>  : t('qr_codes.print_info'))}
            />
            <main>
                {
                    step === 0 &&
                    <>
                        <Radio.Group className={styles.radioGroup} onChange={(e: RadioChangeEvent) => setSelectionType(e.target.value)} value={selectionType}>
                            <Space direction="vertical">
                                <Radio value="empty-codes">
                                    {t('qr_codes.empty_codes')}
                                    {selectionType === "empty-codes"
                                        ? <InputNumber value={emptyCodesNumber} onChange={setEmptyCodesNumber} size="small" type="number" style={{ width: 100, marginLeft: 10 }} />
                                        : null
                                    }
                                </Radio>
                                <Radio value="assigned-codes-all">{t('qr_codes.assigned_codes_all')}</Radio>
                                <Radio value="assigned-codes-selected">{t('qr_codes.assigned_codes_selected')}</Radio>
                            </Space>
                        </Radio.Group>
                        {
                            selectionType === 'assigned-codes-selected' &&
                            <div className={styles.codeSelector}>
                                {
                                    filteredCodes.map((codeKey) => {
                                        const value = codesMap[codeKey];
                                        return (
                                            <CodeInfo codeKey={codeKey} onClick={()=> onItemClick(codeKey)} value={value} isSelected={selected.includes(codeKey)} />
                                        );
                                    })
                                }
                            </div>
                        }
                        <Button type="primary" onClick={() => setStep(step + 1)} >
                            {t('next', {ns: "common"})}
                        </Button>
                    </>
                }
                {
                    step === 1 &&
                    <>
                    <div className={styles.printHeader}>
                        <div>
                            <div>
                                <span>{t('qr_codes.size')}</span>
                                <Slider min={50} max={400} className={styles.slider} value={size} onChange={(value) => setSize(value)} />
                            </div>
                        </div>
                        <div>
                            <Button onClick={() => setStep(step - 1)} >
                                {t('back', {ns: "common"})}
                            </Button>
                            <Button type="primary" onClick={() => window.print()} >
                                {t('qr_codes.print')}
                            </Button>
                        </div>
                    </div>
                    <div className={styles.printPreview}>
                        <header>
                            {t('qr_codes.preview')}
                        </header>
                        <div>
                            {
                                selectedCodes.map((codeKey) => {
                                    const value = codesMap[codeKey];
                                    return (
                                        <div className={styles.printItem} key={codeKey}>
                                            <QRCodeSVG size={size} value={`https://app.georiddle.com/${org.data?.id}/code/${codeKey}`}/>
                                            {
                                                selectionType !== 'empty-codes' &&
                                                <>
                                                    <span>{value.split(/\//g)[0]}</span>
                                                    <span>{value.split(/\//g)[1]}</span>
                                                </>
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    </>
                }
            </main>
        </div>
    )
}


export {QrCodes}