import React, {useCallback} from 'react'

import {useTranslation} from "react-i18next";
import {createId, undefinedToNull} from "@packages/commons/src/object";
import {useNavigate, useParams} from "react-router-dom";
import {
    useAdventure,
    useOrganization,
    useSetAdventure,
    useSetOrganization,
    useSetOrganizationCode
} from "@hooks/useFirestore";
import {AdventureForm, AdventureFormResult} from "@components/screens/adventure/adventure-form";
import {RiddleForm, RiddleFormResult} from "@components/screens/riddle/riddle-form";
import {Spinner} from "@packages/components/common/spinner";
import {Adventure, Riddle, RiddleState} from "@packages/types/domain";

import styles from './create-riddle.module.less'
import {ROUTES} from "../../../routes";
import {Centered} from "@packages/components/layout/centered";
import {withAdventure, WithAdventureProps} from "@components/common/withAdventure";
import {modifyOrgCodes} from "@packages/commons/src/domain";



const CreateRiddle = withAdventure(({adventure}: WithAdventureProps) => {
    const { t } = useTranslation()
    const mutation = useSetAdventure()
    const organizationCodeMutation = useSetOrganizationCode()
    const navigate = useNavigate();

    const onSubmit = async (data: RiddleFormResult) => {
        const riddle: Riddle = {
            ...undefinedToNull(data),
            id: createId(data.name),
            state: 'draft',
        }

        try {
            await mutation.mutate({
                ...adventure,
                riddles: [riddle, ...(adventure.riddles || [])]
            })

            if (modifyOrgCodes(riddle.type, data.code)) {
                await organizationCodeMutation.mutate({code: data.code!, adventure: adventure.id, riddle: riddle.id})
            }
        } catch (e) {

        }
        navigate(`/${ROUTES.ADVENTURES}/${adventure.id}`)
    }

    const onCancel = () => {
        navigate(`/${ROUTES.ADVENTURES}/${adventure.id}`)
    }

    const validateName = (name: string) => {
        return !adventure.riddles.find(riddle => createId(name) === riddle.id)
    }

    return (
        <div>
            <h2>{t('create_riddle.header')}</h2>
            <RiddleForm validateName={validateName}  isLoading={mutation.isLoading || organizationCodeMutation.isLoading} onSubmit={onSubmit} onCancel={onCancel} />
        </div>
    )
})

export {CreateRiddle}