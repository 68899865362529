import { Menu } from "antd";
import {FunctionComponent, useCallback, useMemo} from "react";
import {logout} from "@components/auth";
import {Link, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {ROUTES} from "../../../routes";
import type { MenuProps } from 'antd';

interface UserMenuProps {}

type LinkMenuProps = {
    name: string,
    path: string,
    disabled?: boolean
}

const LinkMenu = ({name, path, disabled = false}: LinkMenuProps) => {
    const navigate = useNavigate();
    const onClick = useCallback(async () => {
        navigate(`/${path}`);
    }, [navigate])
    
    return <Menu.Item disabled={disabled} key={name} onClick={onClick} >{name}</Menu.Item>
}

const LOGOUT_KEY = 'logout'

const MENU_ITEMS = [
    {
        label: 'hamburger.adventures',
        key: ROUTES.ADVENTURES
    },
    {
        label: 'hamburger.qr_codes',
        key: ROUTES.QR_CODES
    },
    {
        label: 'hamburger.find_riddle',
        key: ROUTES.FIND_RIDDLE
    },
    {
        label: 'hamburger.check_prize',
        key: ROUTES.CHECK_PRIZE
    },
    {
        label: 'hamburger.logout',
        key: LOGOUT_KEY
    },
]
 
const UserMenu: FunctionComponent<UserMenuProps> = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleMenuClick = useCallback<NonNullable<MenuProps['onClick']>>( ({item, key}) => {
        if (key === LOGOUT_KEY) {
            logout().then(() => {
                navigate(`/${ROUTES.SIGN_IN}`)
            });
        } else {
            navigate(`/${key}`)
        }
    }, [navigate])

    const items = useMemo(() => {
        return MENU_ITEMS.map(item => ({...item, label: t(item.label)}))
    }, [t])

    return (
        <Menu onClick={handleMenuClick} items={items} />
    );
};


export default UserMenu;