import React, {useCallback, useState} from "react";
import {Breadcrumb, Button, Dropdown, Empty, List, Menu, Popconfirm, Tooltip} from "antd";
import {useTranslation} from "react-i18next";
import {Link, useParams} from "react-router-dom";
import {ROUTES} from "../../../routes";
import {useAdventure, useSetAdventure, useSetOrganizationCode, useUser} from "@hooks/useFirestore";
import {Spinner} from "@packages/components/common/spinner";
import {AdventureComponent} from "@packages/components/adventure";

import styles from './single-adventure.module.less'
import {Riddle} from "@packages/types/domain";
import {RiddleComponent} from "@packages/components/riddle/riddle";
import {
    ArrowDownOutlined,
    ArrowUpOutlined,
    DeleteOutlined,
    DollarTwoTone,
    EditOutlined,
    EllipsisOutlined,
    ExpandOutlined, EyeOutlined
} from "@ant-design/icons";
import {ReactComponent as Back} from "@packages/components/icons/back.svg";
import clsx from "clsx";
import {withAdventure, WithAdventureProps} from "@components/common/withAdventure";
import {changeRiddleStatus, isRiddlePublishable} from "@packages/commons/src/domain";
import {IS_PRODUCTION} from "@packages/commons/src/env";

const NoRiddles = () => {
    const {t} = useTranslation();
    return (<Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        imageStyle={{
            height: 60,
        }}
        description={
            <span>{t('adventure.no_riddle_header')}</span>
        }
    >
        <Link to={'riddles/create'}>
            <Button type="primary">{t('adventure.create_riddle')}</Button>
        </Link>
    </Empty>)
}

type SingleAdventureProps = {}

type RiddleListProps = {
    riddles: Riddle[]
    adventureId: string
}

const RiddleList = ({riddles, adventureId}: RiddleListProps) => {
    const {t} = useTranslation();
    const mutation = useSetAdventure()
    const [mutationId, setMutationId] = useState<string | null>(null)
    const organizationCodeMutation = useSetOrganizationCode()
    const user = useUser()

    const isLoading = useCallback((riddle: Riddle) => {
        return riddle.id === mutationId
    }, [mutationId])

    const isDisabled = useCallback((riddle: Riddle) => {
        return !isRiddlePublishable(riddle)
    }, [riddles, mutationId])

    const unPublishRiddle = useCallback(async (riddle: Riddle) => {
        setMutationId(riddle.id)
        await mutation.mutate({
            id: adventureId,
            riddles: changeRiddleStatus(riddles, riddle, 'inactive')
        }, {
            onSettled: () => {
                setMutationId(null)
            }
        })
    }, [mutation, isDisabled, isLoading])

    const publishRiddle = useCallback(async (riddle: Riddle) => {
        if (isDisabled(riddle)) {
            return;
        }
        setMutationId(riddle.id)
        await mutation.mutate({
            id: adventureId,
            riddles: changeRiddleStatus(riddles, riddle, 'published')
        }, {
            onSettled: () => {
                setMutationId(null)
            }
        })
    }, [mutation, isDisabled, isLoading])

    const deleteRiddle = useCallback(async (riddle: Riddle) => {
        if (isLoading(riddle)) {
            return;
        }
        setMutationId(riddle.id)
        await mutation.mutate({
            id: adventureId,
            riddles: riddles.filter((r) => r.id !== riddle.id)
        }, {
            onSettled: () => {
                setMutationId(null)
            }
        })
        if (riddle.code) {
            organizationCodeMutation.mutate({code: riddle.code, adventure: adventureId, riddle: riddle.id, remove: true})
        }
    }, [mutation, isLoading])

    const openInNewTab = (url: string) => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    const openPreview = useCallback((riddleId: string) => {
        const urlPrefix = IS_PRODUCTION ? `https://app.georiddle.com/` : process.env.REACT_APP_PREVIEW_PREFIX
        openInNewTab(`${urlPrefix}${user.data?.organizationId}/adventure/${adventureId}/riddle/${riddleId}?mode=preview`)
    }, [adventureId, user])

    return (<List
        className={styles.list}
        dataSource={riddles.sort((a, b) => a.name.localeCompare(b.name))}
        renderItem={item => (
            <List.Item
                key={item.id}
                actions={[
                    item.state === 'published'
                        ? <Button disabled={isLoading(item)} onClick={() => unPublishRiddle(item)} size="small" type="text" icon={<ArrowDownOutlined />} >
                            {t('adventure.unpublish_riddle')}
                        </Button>
                        : <Button disabled={isLoading(item) || isDisabled(item)} onClick={() => publishRiddle(item)} size="small" type="text" icon={<ArrowUpOutlined />} >
                            {t('adventure.publish_riddle')}
                        </Button>
                    ,
                    <Link to={`riddles/${item.id}/edit`}>
                        <Button size="small" type="text" icon={<EditOutlined />} >
                            {t('adventure.edit_riddle')}
                        </Button>
                    </Link>,
                    <Tooltip title={t('adventure.riddle_preview')} >
                        <Button disabled={!isRiddlePublishable(item)} size="small" type="text" icon={<EyeOutlined />} onClick={() => openPreview(item.id)} />
                    </Tooltip>,
                    <Popconfirm
                        title={t('adventure.delete_riddle')}
                        onConfirm={() => deleteRiddle(item)}
                        okText={t('yes')}
                        cancelText={t('no')}
                    >
                        <Button size="small" disabled={isLoading(item)} type="text" icon={<DeleteOutlined />} />
                    </Popconfirm>
                ]}
            >
                <RiddleComponent mode="user" riddle={item} />
            </List.Item>
        )}
    />)
}

const SingleAdventure = withAdventure(({adventure}: WithAdventureProps) => {
    const {t} = useTranslation();
    const riddlesLength = adventure.riddles?.length || 0

    return (
        <div className={styles.root}>
            <header>
                <div>
                    <Link to={`/${ROUTES.ADVENTURES}`}>
                        <Button type="text" icon={<Back className={styles.backIcon} />} />
                    </Link>
                    <h2>
                        {t('adventure.adventure')}
                    </h2>
                </div>
                <div>
                    <Button>
                        <Link to={'edit'}>
                            {t('adventure.edit')}
                        </Link>
                    </Button>
                </div>
            </header>
            <main>
                <AdventureComponent mode="user" adventure={adventure}/>
            </main>
            <div className={styles.riddles}>
                <header>
                    <h2>{t('adventure.riddles')}</h2>
                    {
                        riddlesLength > 0 &&
                        <Button type="primary">
                            <Link to={'riddles/create'}>
                                {t('adventure.create_riddle')}
                            </Link>
                        </Button>
                    }
                </header>
                <div>
                    {
                        !riddlesLength &&
                        <NoRiddles/>
                    }
                    {
                        !!riddlesLength &&
                        <RiddleList adventureId={adventure.id} riddles={adventure.riddles!}/>
                    }
                </div>
            </div>
        </div>
    )
});

export {SingleAdventure}
